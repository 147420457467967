import React, { useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";

import "./App.css";
import rootReducer from "./reducers/rootReducer";
import useJiraAttachmentUrls from "./hooks/useJiraAttachmentUrls";

import Header from "./components/Header/Header";
import FrbVideo from "./components/FrbVideo/FrbVideo";
import Transcript from "./components/Transcript/Transcript";
import Spinner from "./components/Spinner/Spinner";
import NotFound from "./components/NotFound/NotFound";
import { authUrl } from "./environment";
import {getQueryVariable} from './components/PrivateRoute/PrivateRoute';

export interface AppState {
  currentAction: string;
  actionCreator: string;
  seekTime: number;
  currentSubtitle: string;
  jiraTicket: string | undefined;
  buffering: boolean;
}

export interface VideoControls {
  controls: boolean;
  width: string;
  height?: string;
  id: string;
  muted?: boolean;
  autoPlay?: boolean;
}
export interface ActionType {
  type: string;
  payload?: any;
}

function App() {
  //Refs to control subtitle tracks programatically
  const tracksRef = useRef<Array<HTMLTrackElement | null>>([]);

  //Ticket number from the route
  // let { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("meetingId");

  // storing meeting id in session storage to append it to url in case session redirects to okta.
  if (id) {
    if (localStorage.getItem("meetingId")) {
      localStorage.removeItem("meetingId");
    }
  }

  //global state
  const initialState: AppState = {
    currentAction: "",
    actionCreator: "",
    seekTime: 0,
    currentSubtitle: "",
    jiraTicket: id || "",
    buffering: false,
  };

  //global reducer
  const [state, dispatch] = useReducer(rootReducer, initialState);

  // data from the jira attachments
  const jiraId = id || "";
  const [jiraFetchLoading, jirAttachments, statusCode] = useJiraAttachmentUrls(
    jiraId
  );
  if (statusCode === 401) {
    const meetingId = getQueryVariable("meetingId");
    if (meetingId) {
      window.localStorage.setItem("meetingId", meetingId);
    }
    return (
      <div>

        {window.location.assign(authUrl)}
        <Spinner>Redirecting ....</Spinner>
      </div>
    );
  }
  const isJiraAttachmentsNull = Object.values(jirAttachments).every(
    (val) => val === "" || val.length === 0
  );
  return (
    <>
      {jiraFetchLoading ? (
        <Spinner>Fetching attachments info from JIRA</Spinner>
      ) : (
        <>
          <Header />

          {jiraId && !isJiraAttachmentsNull ? (
            <main className="main">
              <div className="main-video">
                <FrbVideo
                  url={
                    jirAttachments.shared_screen ||
                    jirAttachments.shared_screen_with_speaker_view
                  }
                  options={{
                    controls: true,
                    width: "100%",
                    id: "transcript",
                    muted: true,
                  }}
                  state={state}
                  dispatch={dispatch}
                >
                  {jirAttachments.audio_transcript?.length
                    ? jirAttachments.audio_transcript.map(
                        (transcript, index) => {
                          return (
                            <track
                              label={`Transcript${index}`}
                              kind="subtitles"
                              id={`audio-transcript${index}`}
                              data-track-id={index}
                              srcLang="en"
                              src={transcript}
                              ref={(el) => (tracksRef.current[index] = el)}
                              key={index}
                              default={index === 0}
                            />
                          );
                        }
                      )
                    : null}
                </FrbVideo>
              </div>

              <div className="main-video-supplements">
                {jirAttachments.active_speaker ? (
                  <div className="speaker-video">
                    <FrbVideo
                      url={jirAttachments.active_speaker}
                      options={{
                        controls: true,
                        width: "100%",
                        height: "100%",
                        id: "logs",
                      }}
                      id="1"
                      state={state}
                      dispatch={dispatch}
                    />
                  </div>
                ) : (
                  ""
                )}

                {jirAttachments.audio_transcript?.length ? (
                  <Transcript
                    state={state}
                    dispatch={dispatch}
                    tracksRef={tracksRef}
                  />
                ) : null}
              </div>
            </main>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
}

export default App;
