import React from "react";
import App from "./App";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Auth from "./components/Auth/Auth";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/:id" component={App} />
        <PrivateRoute path="/" component={App} exact={true} />
      </Switch>
    </Router>
  );
}

export default Routes;
