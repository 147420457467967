import React from 'react';
import './NotFound.css';

function NotFound() {
    return (
        <div className="main-container">
            <div className="content-container">
                <div className="main-content">Page not found</div>
                <div className="sub-content">The page you are looking for is not found. Please make sure the meeting id is correct.</div>
            </div>
        </div>
    )
}

export default NotFound
