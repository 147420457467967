import { AppState, ActionType } from '../App';
import { FETCH_LOADING_COMPLETE, VIDEO_PLAYED, VIDEO_PAUSED, VIDEO_SEEKED, VIDEO_SEEK_COMPLETED, SUBTITLE_CHANGED, VIDEO_BUFFERING, VIDEO_CAN_PLAY, VIDEO_BUFFERING_COMPLETE } from '../actions/actions';

export default function (state: AppState, action: ActionType): AppState {
    switch (action.type) {
        case FETCH_LOADING_COMPLETE:
            return {
                ...state
            }
        case VIDEO_PLAYED:
            return {
                ...state,
                currentAction: VIDEO_PLAYED,
                actionCreator: action.payload.actionCreator,
                ...action.payload.buffering && { buffering: action.payload.buffering }
            }
        case VIDEO_PAUSED:
            return {
                ...state,
                currentAction: VIDEO_PAUSED,
                actionCreator: action.payload.actionCreator
            }
        case VIDEO_SEEKED:
            return {
                ...state,
                currentAction: VIDEO_SEEKED,
                actionCreator: action.payload.actionCreator,
                seekTime: action.payload.seekTime
            }
        case VIDEO_SEEK_COMPLETED:
            return {
                ...state,
                currentAction: ''
            }
        case SUBTITLE_CHANGED:
            return {
                ...state,
                currentSubtitle: action.payload.subtitle
            }
        case VIDEO_BUFFERING:
            return {
                ...state,
                currentAction: VIDEO_BUFFERING,
                actionCreator: action.payload.actionCreator,
                buffering: action.payload.buffering
            }
        case VIDEO_CAN_PLAY:
            return {
                ...state,
                currentAction: VIDEO_CAN_PLAY,
                actionCreator: action.payload.actionCreator,
            }
        case VIDEO_BUFFERING_COMPLETE:
            return {
                ...state,
                currentAction: VIDEO_PLAYED,
                actionCreator: action.payload.actionCreator,
                buffering: false
            }
        default:
            return {
                ...state
            }
    }
}