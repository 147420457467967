import React from "react";
import { Route } from "react-router-dom";
import { authUrl, cloudFrontDomain, env } from "../../environment";
import Spinner from "../Spinner/Spinner";

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

function PrivateRoute({ component: Component, ...rest }: any) {
  if(env === 'production' && window.location.href.includes(cloudFrontDomain)) {
    const redirectionUrl = window.location.href.replace(cloudFrontDomain, 'https://zoom.frostbite.ea.com');
    return(
      <Route>
          <div>
            {window.location.assign(redirectionUrl)}
            <Spinner>Redirecting ....</Spinner>
          </div>
      </Route>
    )


  }
  const id = getQueryVariable("meetingId");
  if (document.cookie.indexOf("sessionId=") < 0) {
    if (id) {
      localStorage.setItem("meetingId", id);
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return document.cookie.indexOf("sessionId=") > -1 ? (
          <Component {...props} />
        ) : (
          <div>
            {window.location.assign(authUrl)}
            <Spinner>Validating Session</Spinner>
          </div>
        );
      }}
    ></Route>
  );
}

export default PrivateRoute;
