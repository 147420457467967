import React from "react";
import './Spinner.css';

export interface SpinnerProps {
    children: any
}

function Spinner(props: SpinnerProps) {
  return (
    <>
      <div className="wrapper"></div>
      <div className="overlay"></div>
      <div className="spanner">
        <div className="loading"></div>
        <p>{props.children}</p>
      </div>
    </>
  );
}

export default Spinner;
