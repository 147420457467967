import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function Auth() {
  const location = useLocation();
  const [auth, setAuth] = useState(false);

  const meetingId = localStorage.getItem("meetingId")
    ? localStorage.getItem("meetingId")
    : "";

  const search = location.search.substring(1);
  const qs = search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : null;

  if (qs && qs.sessionId && qs.expiresAt) {
    let expiresAt = new Date(decodeURIComponent(qs.expiresAt));
    document.cookie = `sessionId = ${
      qs.sessionId
    }; expires=${expiresAt.toUTCString()}; path=/;`;
    window.location.assign(`/?meetingId=${meetingId}`);
    if (!auth) {
      setAuth(true);
    }
  } else {
    document.cookie =
    "sessionId =; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    if(auth) {
      setAuth(false);
    }
  }

  return <div>{!auth && <p>You are not authorized to view this page.</p>}</div>;
}

export default Auth;
