import React, { useEffect, SyntheticEvent, useRef } from "react";
import { VideoControls, AppState, ActionType } from "../../App";
import {
  VIDEO_PLAYED,
  VIDEO_PAUSED,
  VIDEO_SEEKED,
  VIDEO_SEEK_COMPLETED,
  VIDEO_BUFFERING,
  VIDEO_CAN_PLAY,
  VIDEO_BUFFERING_COMPLETE,
} from "../../actions/actions";

export interface FrbVideoProps {
  url: string;
  options: VideoControls;
  children?: any;
  refName?: string;
  id?: string;
  state: AppState;
  dispatch: React.Dispatch<ActionType>;
}

function FrbVideo({
  url,
  children,
  options,
  id,
  state,
  dispatch,
}: FrbVideoProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (state.actionCreator && videoRef.current?.id !== state.actionCreator) {
      if (state.currentAction === VIDEO_PLAYED) {
        if (videoRef?.current?.paused) {
          if(videoRef.current && videoRef.current.readyState >= 3) {
            videoRef.current.play();
          }
        }
      }
      if (state.currentAction === VIDEO_PAUSED) {
        if (videoRef && !videoRef.current?.paused) {
          videoRef.current?.pause();
        }
      }

      if (state.currentAction === VIDEO_SEEKED) {
        if (videoRef?.current) {
          videoRef.current.currentTime = state.seekTime;
          if(videoRef.current && videoRef.current?.readyState >= 3) {
            videoRef.current.play();
          }
        }
      }

      if (state.currentAction === VIDEO_BUFFERING) {
        if (videoRef?.current) {
          videoRef.current.pause();
        }
      }

      if(state.currentAction === VIDEO_CAN_PLAY) {
        // Once first video is buffered, check if second video can also play (videoRef.current.readyState >= 3).
        if (videoRef?.current && videoRef.current.readyState >= 3 && state.buffering) {
          dispatch({
            type: VIDEO_BUFFERING_COMPLETE,
            payload: {
              actionCreator: videoRef.current.id,
              buffering: false
            }
          })
          videoRef.current.play();
        }
      }
    }
  }, [state.currentAction, state.actionCreator, state.seekTime, state.buffering, dispatch]);

  const videoPlayed = () => {
    if (state.currentAction !== VIDEO_SEEKED && videoRef?.current?.readyState !== undefined &&  videoRef?.current?.readyState >= 3) {
      dispatch({
        type: VIDEO_PLAYED,
        payload: { actionCreator: videoRef.current?.id },
      });
    }
  };

  const videoPaused = () => {
    if (state.currentAction !== VIDEO_SEEKED) {
      dispatch({
        type: VIDEO_PAUSED,
        payload: { actionCreator: videoRef.current?.id },
      });
    }
  };

  const videoSeeked = (ev: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (state.currentAction !== VIDEO_SEEKED) {
      dispatch({
        type: VIDEO_SEEKED,
        payload: {
          actionCreator: videoRef.current?.id,
          seekTime: videoRef.current?.currentTime,
        },
      });
    }
  };

  const videoSeekCompleted = () => {
    dispatch({
      type: VIDEO_SEEK_COMPLETED,
      payload: {
        actionCreator: videoRef.current?.id,
        seekTime: videoRef.current?.currentTime,
      },
    });
    if(videoRef.current && videoRef.current?.readyState >= 3) {
      videoRef.current?.play();
    } 
    
  };

  const videoBuffering = () => {
    if(!state.buffering) {
      dispatch({
        type: VIDEO_BUFFERING,
        payload: {
          actionCreator: videoRef.current?.id,
          buffering: true,
        },
      });
      videoRef.current?.pause();
    }
  }


  const videoCanPlay = () => {
    if(state.buffering) {
      dispatch({
        type: VIDEO_CAN_PLAY,
        payload: {
          actionCreator: videoRef.current?.id
        }
      })
    }
  }


  return (
    <>
      <video
        src={url}
        {...options}
        onPlayCapture={videoPlayed}
        ref={videoRef}
        onPauseCapture={videoPaused}
        onSeeking={videoSeeked}
        onSeeked={videoSeekCompleted}
        onWaiting={videoBuffering}
        onCanPlay= {videoCanPlay} 
        autoPlay
        data-testid="video"
      >
        {children}
      </video>
    </>
  );
}

export default React.memo(FrbVideo);
