import { useEffect, useState } from 'react';
import axios from 'axios';
import {getCookie} from '../utilities/getCookie';
import {env, apiUrl} from '../environment';


export interface JiraResponse {
    url: string,
    name: AttachmentListKeys
}


export interface AttachmentList {
    shared_screen_with_speaker_view: string,
    shared_screen: string,
    active_speaker: string,
    audio_transcript?: string[],
    TIMELINE?: string
}
type AttachmentListKeys = keyof AttachmentList;


const initialAttachmentList = { shared_screen: '', active_speaker: '', audio_transcript: [], shared_screen_with_speaker_view: '' }


function useJiraAttachementUrls(jiraId: string): [boolean, AttachmentList, number] {
    const [loading, setLoading] = useState(true);
    const [jiraAttachments, setJiraAttachments] = useState<AttachmentList>(initialAttachmentList);
    const [statusCode, setStatusCode] = useState(200);

    useEffect(() => {

        async function getJiraData() {
            const cookie = getCookie('sessionId');
            setLoading(true);

            // For demo: using local assets when no meetingId is given in the url.
            if (!jiraId) {
                setLoading(false);
                const tempData = {
                    active_speaker: '/assets/speaker_video.mp4',
                    shared_screen: '/assets/main_video.mp4',
                    audio_transcript: ['/assets/audio_transcript.vtt', '/assets/frosted_events.vtt'],
                    shared_screen_with_speaker_view: ''
                }
                setJiraAttachments(tempData);

            } else {
                let meetingUrl = `${apiUrl}/v2/zoom/meetings/${jiraId}`;
                try {
                    const res = await axios.get(`${meetingUrl}/zoomurls`, {
                        headers: {
                            'Authorization': `Bearer ${cookie}`
                        }
                    });
                    const jiraResponseList: Array<JiraResponse> | [] = res?.data?.data;

                    let tempData: AttachmentList = {...initialAttachmentList};

                    const videoKeys = ['shared_screen', 'shared_screen_with_speaker_view', 'active_speaker']
                    jiraResponseList.forEach(item => {
                        if (item.name.toLowerCase() !== 'timeline') {
                                let url = cloudfrontRewrite(item.url);
                                if (!videoKeys.includes(item.name)) {
                                    if (!tempData.audio_transcript) {
                                        tempData.audio_transcript = [];
                                    }
                                    let downloadUrl = `${meetingUrl}/download?url=${url}`;
                                    if (item.name === 'audio_transcript') {
                                        tempData.audio_transcript.unshift(downloadUrl);
                                    } else {
                                        if(url.endsWith('.vtt')) tempData.audio_transcript.push(downloadUrl);
                                    }

                                } else {
                                    if (item.name !== 'audio_transcript') {
                                        tempData[item.name] = url;
                                    }
                                }
                            }
                    });

                    if (tempData.audio_transcript?.length) {
                        const promiseList = tempData.audio_transcript.map((url, id) => {
                            return axios.get(url, {
                                headers: {
                                    'Authorization': `Bearer ${cookie}`
                                }
                            });
                        });
                        try {
                            const subtitlesRespList = await Promise.all(promiseList);
                            const subtitleData = subtitlesRespList.map(subtitleResp => {
                                const dataBlob = new Blob([subtitleResp.data.data], {
                                    type: 'text/plain'
                                });
                                return URL.createObjectURL(dataBlob);
                            });
                            tempData.audio_transcript = [...subtitleData];
                        } catch (err) {
                            setLoading(false);
                        }
                    }
                    setJiraAttachments(tempData);
                    setLoading(false);
                }
                catch (err) {
                    setLoading(false);
                    const status = err?.response?.status || 500;
                    setStatusCode(status);
                }

            }


        }
        getJiraData();
    }, [jiraId])

    return [loading, jiraAttachments, statusCode];

}

export function cloudfrontRewrite(url:string){
    if(env === 'production' && url.includes('d18y25psrumgh3')) {
        return url.replace('d18y25psrumgh3', 'd35r8pvdgbktuz')
    } else return url
}

export default useJiraAttachementUrls
