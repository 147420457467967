/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  MutableRefObject,
  ChangeEvent,
} from "react";
import "./Transcript.css";
import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";
import { AppState, ActionType } from "../../App";
import Dropdown from "../Dropdown/Dropdown";
import { SUBTITLE_CHANGED, VIDEO_SEEKED } from "../../actions/actions";

export interface TranscriptProps {
  state: AppState;
  dispatch: React.Dispatch<ActionType>;
  tracksRef: React.MutableRefObject<Array<HTMLTrackElement | null>>;
}

function Transcript({ state, dispatch, tracksRef }: TranscriptProps) {
  const [transcriptText, setTranscriptText] = useState([
    {
      id: "",
      startTime: 0,
      timeString: "",
      text: "",
    },
  ]);

  const [filteredtranscriptText, setFilteredTranscriptText] = useState([
    {
      id: "",
      startTime: 0,
      timeString: "",
      text: "",
    },
  ]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch({
      type: SUBTITLE_CHANGED,
      payload: {
        subtitle: "audio-transcript0",
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (tracksRef.current.length) {
      //disable all subtitles
      tracksRef.current.forEach((ref, id) => {
        if (ref?.track?.mode) {
          ref.track.mode = "disabled";
        }
      });
      
      //load current subtitle;
      const currentSubtitleRefList = tracksRef.current.filter((ref, id) => {
        return ref?.id === state.currentSubtitle;
      });

      const currentSubtitleRef = currentSubtitleRefList?.[0];

      const displayCues = (track: TextTrack) => {
        var cues = track.cues;
        let temp = [];
        for (let i = 0; i < cues.length; i++) {
          const cue = cues[i];

          cue.onenter = function () {

            var oldTranscriptText = document.querySelector(
              ".list-group-item.active"
            );
            if (oldTranscriptText) {
              oldTranscriptText.classList.remove("active");
            }
            // Highlight current cue
            var transcriptText = document.getElementById(this.id);
            transcriptText?.classList.add("active");
            transcriptText?.scrollIntoView();
          };

          //show transcript elements in the div
          const text = cue.text;
          const date = new Date(0);
          date.setSeconds(cue.startTime);
          const timeString = date.toISOString().substr(11, 8);

          let cueObj = {
            id: cue.id,
            startTime: cue.startTime,
            timeString,
            text,
          };
          temp.push(cueObj);
        }
        setTranscriptText(temp);
        setFilteredTranscriptText(temp);
        setSearchText("");
      };

      if (currentSubtitleRef?.track) {
        let track = currentSubtitleRef.track;
        track.mode = "hidden";
        // subtitle will not be shown in the video, but in a separate div

        //show track in transcript div
        if (currentSubtitleRef.readyState === 2) {
          //track is ready to load; display the cues
          displayCues(track);
        } else {
          // wait for track to load and then display the cues
          currentSubtitleRef.addEventListener("load", (e) => {
            displayCues(track);
          });
        }
      }
    }
  }, [state.currentSubtitle, tracksRef]);

  useEffect(() => {
    const setCueActive = (track: TextTrack, flooredSeekTime: Number) => {
      if(track?.cues?.length) {
        let nearestCue = {
          startTime: -1,
          id: ''
        };
        Array.from(track.cues).forEach(cue => {
          const flooredCueStartTime = Math.floor(+cue.startTime)
          if(Number.isNaN(flooredCueStartTime)) {
            return;
          }
          if(flooredCueStartTime <= flooredSeekTime) {
            if(nearestCue.startTime === -1) {
              nearestCue.startTime = flooredCueStartTime;
              nearestCue.id = cue.id;
              return;
            }
            if(nearestCue.startTime < flooredCueStartTime) {
              nearestCue.startTime = flooredCueStartTime;
              nearestCue.id = cue.id;
            }
          }
        });
        var oldTranscriptText = document.querySelector(
          ".list-group-item.active"
        );
        if (oldTranscriptText) {
          oldTranscriptText.classList.remove("active");
        }
        // Highlight current cue
        if(nearestCue.id !== '') {
          var transcriptText = document.getElementById(nearestCue.id);
          transcriptText?.classList.add("active");
          transcriptText?.scrollIntoView();
        }
      }
    }

    // on seek, change active cue to the nearest cue if cue does not exist for the given time.
    if(state.currentAction === VIDEO_SEEKED) {
      const flooredSeekTime = Math.floor(+state.seekTime);
      if(!Number.isNaN(flooredSeekTime)) {
        const currentSubtitleRef = tracksRef.current.find(ref => ref?.id === state.currentSubtitle);
        if(currentSubtitleRef && currentSubtitleRef.readyState >= 2) {
          setCueActive(currentSubtitleRef.track, flooredSeekTime);
        }
      }
    }

  }, [state.currentAction, state.seekTime, state.currentSubtitle, tracksRef])


  const jumpTo = (time: number) => {
    dispatch({
      type: VIDEO_SEEKED,
      payload: {
        actionCreator: "subtitles",
        seekTime: time,
      },
    });
  };

  const searchHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextVal = ev.target.value;
    setSearchText(searchTextVal);
    if (searchTextVal === "") {
      setFilteredTranscriptText(transcriptText);
      return;
    }
    const filteredText = transcriptText.filter((val, id) =>
      val.text.toUpperCase().includes(searchTextVal.toUpperCase())
    );
    setFilteredTranscriptText(filteredText);
  };

  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="card-body-header">
          <h5 className="card-title">Transcript</h5>
          <Dropdown
            state={state}
            dispatch={dispatch}
            options={tracksRef || []}
          />
        </div>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search transcript"
            aria-label="Search transcript"
            aria-describedby="basic-addon2"
            onChange={(ev) => searchHandler(ev)}
            value={searchText}
          />
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
              {/* <i className="fab fab-search" aria-hidden="true"></i> */}
              <SearchIcon />
            </span>
          </div>
        </div>
        <div className="list-group list-group-flush transcript-body">
          <div id="transcript">
            {filteredtranscriptText.map((val, id) => {
              return (
                <small
                  className="cues list-group-item list-group-item-action"
                  id={val.id}
                  key={val.id}
                  onClick={() => {
                    jumpTo(val.startTime);
                  }}
                >
                  <div className="text-info">{val.timeString}</div>
                  {val.text}
                </small>
              );
            })}
          </div>
        </div>
        <small className="card-footer text-muted text-right">
          Powered by Zoom
        </small>
      </div>
    </div>
  );
}

export default React.memo(Transcript);
