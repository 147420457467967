// API ACTIONS
export const FETCH_LOADING = 'FETCH_LOADING';
export const FETCH_LOADING_COMPLETE = 'FETCH_LOADING_COMPLETE';
export const FETCH_ERROR = 'FETCH_ERROR';

//JIRA FETCH ACTIONS
export const JIRA_ATTACHMENTS_LOADED = 'JIRA_ATTACHMENTS';

//VIDEO AND SUBTITLE REFS
export const VIDEO_REF_CHANGED = 'VIDEO_REF_CHANGED';
export const SUBTITLE_REF_CHANGED = 'SUBTITLE_REF_CHANGED';

//VIDEO AND SUBTITLE ACTIONS
export const VIDEO_PLAYED = 'VIDEO_PLAYED';
export const VIDEO_PAUSED = 'VIDEO_PAUSED';
export const VIDEO_SEEKED = 'VIDEO_SEEKED';
export const VIDEO_SEEK_COMPLETED = 'VIDEO_SEEK_COMPLETED';
export const SUBTITLE_CHANGED = 'SUBTITLE_CHANGED';
export const VIDEO_BUFFERING = 'VIDEO_BUFFERING';
export const VIDEO_CAN_PLAY = 'VIDEO_CAN_PLAY';
export const VIDEO_BUFFERING_COMPLETE = 'VIDEO_BUFFERING_COMPLETE';
