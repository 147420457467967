/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { AppState, ActionType } from "../../App";
import { SUBTITLE_CHANGED } from "../../actions/actions";

export interface DropdownProps {
  options: React.MutableRefObject<Array<HTMLTrackElement | null>>;
  state: AppState;
  dispatch: React.Dispatch<ActionType>;
}
function Dropdown({ state, dispatch, options }: DropdownProps) {
  const [showDropdown, setshowDropdown] = useState(false);

  const outerDivRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = (ev: { preventDefault: () => void; }) => {
    ev.preventDefault();
    setshowDropdown((prevShowDropDown) => !prevShowDropDown);
  };

  const selectSubtitle = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>, subtitle: string) => {
    ev.preventDefault();
    ev.stopPropagation();
    dispatch({
      type: SUBTITLE_CHANGED,
      payload: {
        subtitle,
      },
    });
    setshowDropdown(false);
  };

  const clickHandler = useCallback(
    (ev: MouseEvent) => {
      if (outerDivRef.current?.contains(ev.target as Node)) {
        return;
      }
      if (showDropdown) {
        setshowDropdown(false);
      }
    },
    [showDropdown]
  );

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [clickHandler]);

  return (
    <div className="dropdown" ref={outerDivRef}>
      <a
        href="#"
        className="dropdown-toggle"
        data-toggle="dropdown"
        onClick={toggleMenu}
      >
        Choose Source &nbsp;
      </a>
      <div
        className={`dropdown-menu ${showDropdown ? "show" : ""}`}
        style={
          showDropdown
            ? {
                position: "absolute",
                transform: "translate3d(0px, 21px, 0px)",
                top: "0px",
                left: "0px",
              }
            : { position: "initial" }
        }
      >
        {options.current.map((ref, id) => {
          return (
            <a
              href="#"
              className={`dropdown-item`}
              id="select-audio-transcript"
              onClick={(ev) => selectSubtitle(ev, ref?.id || '')}
              key={ref?.id || id}
            >
              {ref?.label}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Dropdown;
